import { XIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { ITopBanner } from 'features/common/types'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect } from 'react'

import styles from './HeaderTopBanner.module.scss'
interface ITopBannerProps {
  isExternalUrl?: boolean
  bannerData: ITopBanner | undefined
  isDialogOpened: boolean
  onDialogOpenHandler: () => void
  onDialogCloseHandler: () => void
}

const HeaderTopBanner: FC<ITopBannerProps> = ({
  isExternalUrl = false,
  bannerData,
  isDialogOpened,
  onDialogOpenHandler,
  onDialogCloseHandler,
}) => {
  const router = useRouter()

  const goToUrl = useCallback(() => {
    return isExternalUrl
      ? window.open(bannerData?.redirectionPath)
      : router.push(bannerData?.redirectionPath as string)
  }, [router])

  const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
  const hasClickClosed = localStorage.getItem('close')
  const visitDate = dayjs().format(dateFormat)
  const After2Hour = dayjs(hasClickClosed).add(2, 'hour').format(dateFormat)

  const onClose = useCallback(e => {
    e.stopPropagation()
    onDialogCloseHandler()
    localStorage.setItem('close', visitDate)
  }, [])

  useEffect(() => {
    if (!hasClickClosed) {
      onDialogOpenHandler()
    }
    if (dayjs().isAfter(After2Hour)) {
      onDialogOpenHandler()
    }
  }, [hasClickClosed])

  useEffect(() => {
    if (
      bannerData?.displayEnd &&
      dayjs().isAfter(dayjs(bannerData?.displayEnd).format(dateFormat))
    ) {
      onDialogCloseHandler()
      localStorage.removeItem('close')
    }
  }, [bannerData, onDialogCloseHandler])

  return (
    <div
      className={`w-full cursor-pointer overflow-hidden bg-yellow-400 ${
        isDialogOpened ? 'block' : 'hidden'
      }`}
      onClick={goToUrl}
      style={{ backgroundColor: bannerData?.backgroundColor }}>
      <div
        className={`mx-auto flex max-w-7xl items-center justify-between overflow-hidden px-4 py-2 md:py-2.5 lg:px-6 ${styles.TopBannerWrap}`}>
        <div className="flex items-center">
          <p
            className="flex flex-row items-center gap-x-2 whitespace-nowrap text-sm font-medium md:text-base md:font-semibold"
            style={{ color: bannerData?.textColor }}>
            {bannerData?.bannerDescription}
          </p>
          {bannerData && (
            <Image
              src={bannerData.imgSrc}
              alt="topBanner"
              className="ml-2 block h-auto w-[52px] object-cover"
              width={52}
              height={46}
              layout="fixed"
            />
          )}
        </div>
        <div
          className="z-10 inline-flex h-5 w-5 cursor-pointer items-center justify-center"
          onClick={onClose}>
          <XIcon className="h-full w-full" />
        </div>
      </div>
    </div>
  )
}

export default HeaderTopBanner
