import { useState } from 'react'

export const useDialog = () => {
  const [isDialogOpened, setIsDialogOpen] = useState(false)
  const onDialogOpenHandler = () => setIsDialogOpen(true)
  const onDialogCloseHandler = () => setIsDialogOpen(false)
  return {
    isDialogOpened,
    onDialogOpenHandler,
    onDialogCloseHandler,
  }
}
