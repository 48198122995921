import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { forwardRef, useCallback } from 'react'

import { IProfile } from '../../stores/auth'
import styles from './Dropdown.module.scss'

interface IDropdown {
  profile: IProfile | null
}

const Dropdown = forwardRef<HTMLDivElement, IDropdown>(({ profile }, ref) => {
  const router = useRouter()
  const logout = useCallback(() => router.push('/oauth/logout'), [router])

  return (
    profile && (
      <div
        ref={ref}
        className={`overflow-hidden rounded-lg border border-gray-200 bg-white ${styles.container}`}>
        <header className="flex bg-white px-6 py-6 lg:flex-col">
          <div className="ml-3 flex flex-col lg:ml-0">
            <h3 className="text-base font-bold">{profile.name} 님</h3>
            <p className="font-noraml text-sm text-gray-500">{profile.email}</p>
          </div>
        </header>
        <HR />
        <main>
          <nav>
            <ul className="hidden flex-col py-4 text-sm lg:flex">
              {dropDownList.map(({ name, href }, idx) => (
                <li key={`${name}_${idx}`}>
                  <a href={href} className="block h-full w-full px-6 py-3">
                    <span className={`relative ${styles.newIcon}`}>{name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <HR />
          <section className="hidden py-5 pl-6 lg:block">
            <button onClick={logout}>
              <span className="text-xs text-gray-500 underline">로그아웃</span>
            </button>
          </section>
        </main>
      </div>
    )
  )
})

const HR = () => <hr className="hidden bg-gray-200 lg:block" />

const dropDownList = [
  {
    name: '강의목록',
    href: '/my/courses',
  },
  {
    name: '수강신청',
    href: '/my/apply-status',
  },
  // {
  //   name: 'Q&A',
  //   href: '/my/study-qna',
  // },
  {
    name: '결제 내역 / 환불',
    href: '/my/payments',
  },
  {
    name: '내 쿠폰',
    href: '/my/coupons',
  },
  {
    name: '회원 정보 수정',
    href: '/my/profile',
  },
  {
    name: '비밀번호 변경',
    href: '/my/change-password',
  },
]

Dropdown.displayName = 'Dropdown'
export default Dropdown
